import { VIDEO_POPUP_SIZE } from './constants'

export const openVideoPopup = function(videoUrl, size = VIDEO_POPUP_SIZE.small) {
  return {
    type: 'OPEN_VIDEO_POPUP',
    size,
    videoUrl
  }
}

export const closeVideoPopup = function(){
  return { type: 'CLOSE_VIDEO_POPUP' }
}
