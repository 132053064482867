import * as React from 'react'
import { defaultState, CartReducerState } from './reducer'
import { ShippingInformation } from './types'

const { createContext, useContext } = React

type CartContextResponse = {
  addSailToCart: () => void,
  deleteSailFromCart: () => void
  updateSailInCart:  () => void,
  setPostcode:  () => void,
  setShippingInformation: (shippingInformation: ShippingInformation) => void,
  dispatch:  () => void,
  reducerState: CartReducerState,
  clearCart: () => void,
  setShippingAddress: () => void, 
  clearExtendedWarranty: () => void,
  clearSelectedCouponCode: () => void,
  showWhatHappensNextDialog: () => void,
  hideWhatHappensNextDialog: () => void,
  applySelfInstallDiscount: () => void,
}

export const CartContext = createContext({ reducerState: defaultState })

export const useCartContext = (): CartContextResponse => useContext(CartContext)