import { formatNumber } from '../../llc/formatting/string.cjs'
import {
  calculateSailWidths as calculateSailWidthsImported,
  //getPoleCombinationForSailWidth as getPoleCombinationForSailWidthImported,
  PRICE_LIST as PRICE_LISTImported,
  validateGetShadeSailItemsParams as validateGetShadeSailItemsParamsImported,
  getShadeSailItems as getShadeSailItemsImported,
  calculateNumberOfBridges as calculateNumberOfBridgesImported,
  wireTensioners as wireTensionersImported,
  wallOptions as wallOptionsImported,
  getUnitRatioToMM as getUnitRatioToMMImported,
  computeStackingEndInstallingStructure as computeStackingEndInstallingStructureImported,
  computeExtendingEndInstallingStructure as computeExtendingEndInstallingStructureImported,
} from './utilNode.cjs'
import { ItemCost } from './../CartLogic/types.ts'

type PoleCombination = ['A', 'B', 'C']

type SailWidth = {
  width: number,
  poles: PoleCombination,
}


export const calculateSailWidths = function(params = {}): SailWidth[]{
  return calculateSailWidthsImported(params)
}

/**
 * These sail widths are in mm
 */
/*export const validSailWidths = [
  1720,
  1930,
  2070,
  2210,
  2670,
  2810,
  2880,
  3020,
  3160,
  3300,
  3410,
  3620,
  3760,
  3830,
  3900,
  3970,
  4110,
  4250,
  4360,
  4390,
  4500,
  4570,
  4710,
  4850,
  4990,
  5100,
  5200,
  5310,
  5450,
  5590,
  5800,
  5940,
  6050,
]*/
export const validSailWidths = calculateSailWidths().map(o => o.width)

export const validSailLengths = [
  300,
  400,
  500,
  600,
  700,
  800,
  900,
  1000,
  1100,
  1200,
  1300,
  1400,
  1500,
]

export const wireTensioners = wireTensionersImported

export const getMatchingWireTensioners = function(params){
  
  let { 
    lengthBetweenStructures,
    stackingEndType,
    extendingEndType
  } = params

  if (
    lengthBetweenStructures === undefined 
    || lengthBetweenStructures === null
    || Number.isNaN(Number(lengthBetweenStructures))
  )
    return []

  lengthBetweenStructures = Number(lengthBetweenStructures)

  const addOthersAsNotRecommended = vals => {
    const dtos = wireTensioners.map(o => {
      const existing = vals.find(o2 => o2.value == o.value)
      if (existing)
        return existing
      return {
        ...o,
        notRecommended: true,
      }
    })
    return dtos
  }

  if (lengthBetweenStructures < 7000){

    const threadedTerminal = wireTensioners.find(o => o.value == 'threaded-terminal')
    const m8Val = wireTensioners.find(o => o.value == 'm8')
    var m10Val = wireTensioners.find(o => o.value == 'm10')
    var m12Val = wireTensioners.find(o => o.value == 'm12')

    if (stackingEndType == 'WALL' && extendingEndType == 'WALL'){
      // In this scanerio threaded terminal can't be an option
      return [
        { 
          recommended: true, 
          ...m8Val,
        },
        {
          notRecommended: true, 
          ...m10Val,
        },
        { 
          notRecommended: true, 
          ...m12Val,
        },
      ]
    }

   
    return addOthersAsNotRecommended([
      {
        ...threadedTerminal,
        recommended: true,
      },
      m8Val,
    ])
  }

  if (lengthBetweenStructures <= 12500){
    var m10Val = wireTensioners.find(o => o.value == 'm10')
    return addOthersAsNotRecommended([{
      ...m10Val,
      recommended: true,
    }])
  }

  var m12Val = wireTensioners.find(o => o.value == 'm12')
  return addOthersAsNotRecommended([{
    ...m12Val,
    recommended: true,
  }])
  
}

export const getFabricDropOptions = function(params){

  const {
    unit = 'mm',
    sailAreaSize,
  } = params

  const vals = []

  if (sailAreaSize <  30 * (1000 * 1000)){
    vals.push(
      275,
      300
    )
  }
  if (sailAreaSize < 48 * (1000 * 1000)){
    vals.push(
      325,
      350,
      375,
    )
  }
  vals.push(
    400,
    425, 
    450, 
    475,
    500,
    525,
    550,
  )

  vals.reverse()

  return vals.map(v => {

    return {
      'label': `${formatNumber(v / getUnitRatioToMM(unit))}${unit}`,
      'value': v,
    }
  })
}



/**
 * Calculation is done in mm
 */
export const calculateMaxSailLength = function(params){
  const {
    lengthBetweenStructures,
    riggingMethod
  } = params

  if (
    !riggingMethod
  ){
    return null
  }

  
 

  let deduction = 0

  if (riggingMethod == 'FLOATING'){
    deduction = 370
  } else {
    deduction = 250
  } 

  
  return lengthBetweenStructures - (deduction)

}

export const getStackingEndGap = function(){
  return 70
}


export const getExtendingEndGap = function(params){
  const {
    wireTensioner,
  } = params

  if (!wireTensioner) 
    return 400
  
  const validWireTensioners = wireTensioners.map(o => o.value)
  if (validWireTensioners.includes(wireTensioner) == false)
    throw new Error(`Invalid wireTensioner ${wireTensioner}`)

  let wireTensionerAdjustment = 0

  /**
   * "So can you please program it so that the sail length is limited to the 
   * maximum length possible when you deduct the specified total gap required 
   * for specific terminals, from the length between structures. I will list 
   * them below:
   * Threaded terminal – 130mm/13cm (this is to be deducted from length 
   * between structures)
   * M8 rigging screw – 410mm/41cm (“)
   * M10 turnbuckle – 470mm/47cm (“)
   * M12 turnbuckle – 520mm/52cm (“)
   */

  switch(wireTensioner){
  case 'threaded-terminal':
    wireTensionerAdjustment = 50
    break
  case 'm8':
    wireTensionerAdjustment = 340
    break
  case 'm10':
    wireTensionerAdjustment = 400
    break
  case 'm12':
    wireTensionerAdjustment = 450
    break
  }

  return wireTensionerAdjustment
}

/**
 * Get the ratio you would need to apply to this unit to convert it to mm
 */
export const getUnitRatioToMM = unit => {

  return getUnitRatioToMMImported(unit)

}


type CalculateNumberOfBridgesParams = {
  /**
   * Sail length in mm.
   */
  sailLength: number,

  /**
   * The max fabric drop in mm.
   */
  maxFabricDrop: number,
}

type CalculateNumberOfBridgesReturnObject = {
  /** 
   * The number of bridges, includes the poles on both ends.
   */
  numberOfBridges: number,
  fabricLength: number,
  /**
   * The fabric drop
   */
  drop: number,
}

/**
 * Given the max fabric drop and the sail length, calculate the number of
 * bridges
 */
export const calculateNumberOfBridges = function(params : CalculateNumberOfBridgesParams): CalculateNumberOfBridgesReturnObject | null {
  return calculateNumberOfBridgesImported(params)
}


export const calculateTotalRopeLength = function(params){
  const {
    ropeLengthA,
    ropeLengthB,
    ropeLengthC,
    //unit = 'mm',
    riggingMethod,
    sailLength,
  } = params


  if (typeof ropeLengthA != 'number' || 
    typeof ropeLengthB != 'number' || 
    typeof ropeLengthC != 'number'
  )
    return null

  if (riggingMethod == 'FLOATING'){
    if (typeof sailLength != 'number')
      return null

    return (ropeLengthA + ropeLengthB + ropeLengthC + 600) - sailLength
  }

  return (2 * (ropeLengthA + ropeLengthB + ropeLengthC))
    + 2000
}

export const validateRopeLengthA  = function(params){
  const {
    surfaceToSurface,
    ropeLength,
    unit = 'mm',
  } = params

  if (typeof ropeLength !== 'number' && !ropeLength){
    return 'Required'
  }

  if (typeof ropeLength !== 'number'){
    return 'Invalid'
  }

  if (ropeLength < surfaceToSurface){
    return `Should be at least ${surfaceToSurface}${unit}`
  }
  return null
}

export const validateRopeLengthB  = function(params){
  const {
    ropeLength,
  } = params

  if (typeof ropeLength !== 'number' && !ropeLength){
    return 'Required'
  }

  if (typeof ropeLength !== 'number'){
    return 'Invalid'
  }


  return null    
}


export const validateRopeLengthC = function(params){
  const {
    ropeLength,
    unit = 'mm'
  } = params

  if (typeof ropeLength !== 'number' && !ropeLength){
    return 'Required'
  }

  if (typeof ropeLength !== 'number'){
    return 'Invalid'
  }

  if (ropeLength < (1000 / getUnitRatioToMM(unit)))
    return `Must be at least ${1000 / getUnitRatioToMM(unit)}${unit}`

  return null

}

export const wallOptions = wallOptionsImported
export const PRICE_LIST = PRICE_LISTImported

export const validateGetShadeSailItemsParams = function(params){
  return validateGetShadeSailItemsParamsImported(params)
}

export const getShadeSailItems = function(params): ItemCost[] {
  return getShadeSailItemsImported(params)
}

export const computeStackingEndInstallingStructure = function(params){
  return computeStackingEndInstallingStructureImported(params)
}


export const computeExtendingEndInstallingStructure = function(params){
  return computeExtendingEndInstallingStructureImported(params)
}