var { formatNumber } = require("../../llc/formatting/string.cjs")
const { assert } = require("../../llc/fs/func.cjs")
var { roundTo3Dp } = require('./../../llc/formatting/number.cjs')
var { postageFlatRateCosts } = require('./../CartLogic/shippingCosts.cjs')
var { priceList, ...PRICE_LIST } = require('./priceListNode.cjs')


/*{
        "label": "Box A",
        "key": "BOX_A",
        "price": 18.638059702735074
    },
    {
        "label": "Box B",
        "key": "BOX_B",
        "price": 15.531716416046638
    },
    {
        "label": "Box C",
        "key": "BOX_C",
        "price": 12.425373133707087
    },
    {
        "label": "Box A Total Shipping Cost",
        "key": "BOX_A_TOTAL_SHIPPING_COST",
        "price": 1285.7142857142856
    },
    {
        "label": "Box B Total Shipping Cost",
        "key": "BOX_B_TOTAL_SHIPPING_COST",
        "price": 1028.5714285714284
    },
    {
        "label": "Box C Total Shipping Cost",
        "key": "BOX_C_TOTAL_SHIPPING_COST",
        "price": 571.4285714285714
    },
    {
        "label": "Box A installation kit shipping cost",
        "key": "BOX_A_INSTALLATION_KIT_SHIPPING_COST",
        "price": 219.1857143
    },
    {
        "label": "Box B installation kit shipping cost",
        "key": "BOX_B_INSTALLATION_KIT_SHIPPING_COST",
        "price": 205.7857143
    },
    {
        "label": "Box C installation kit shipping cost",
        "key": "BOX_C_INSTALLATION_KIT_SHIPPING_COST",
        "price": 193.3428571
    },*/



const POLE_A_LENGTH = 1690
const POLE_B_LENGTH = 1090
const POLE_C_LENGTH = 950

const padPrecedingZeros = function(str, targetLength){
  while (str.length < targetLength){
    str = '0' + str
  }
  return str
}

const calculateSailWidths = function(params = {}){
  const {
    minWidth = 1720,
    maxWidth = 6050,
    allowedWidths = [
      1720,
      1930,
      2070,
      2210,
      2670,
      2810,
      2880,
      3020,
      3160,
      3300,
      3410,
      3620,
      3760,
      3830,
      3900,
      3970,
      4110,
      4250,
      4360,
      4390,
      4500,
      4570,
      4710,
      4850,
      4990,
      5100,
      5200,
      5310,
      5450,
      5590,
      5800,
      5940,
      6050,
    ]
  } = params


  var combinations = []

  var maximumShortPoles = Math.floor(maxWidth / POLE_C_LENGTH)

  for (var i = 0; i < Math.pow(maximumShortPoles, 3); i++){
    
    var val = Number(i).toString(maximumShortPoles)
    val = padPrecedingZeros(val, 3)
    // 000
    // 001
    // 002
    // 003
    // 004
    // 005
    // 010
    // 011
    // 012
    // 013
    // 014
    // 015
    // 020
    // 021

    var poleQuantities = val.split('').map(Number)

    var sailWidth = 
      poleQuantities[0] * POLE_A_LENGTH +
      poleQuantities[1] * POLE_B_LENGTH +
      poleQuantities[2] * POLE_C_LENGTH +
      30 // 30 mm over hang, 15mm on each side
    
    // Sail width is outside of our range
    if (sailWidth < minWidth || sailWidth > maxWidth)
      continue

    var combinationsArray = []
    for (var j = 0; j < poleQuantities[0]; j++){
      combinationsArray.push('A')
    }
    for (var j = 0; j < poleQuantities[1]; j++){
      combinationsArray.push('B')
    }
    for (var j = 0; j < poleQuantities[2]; j++){
      combinationsArray.push('C')
    }

    combinations.push({
      width: sailWidth,
      poles: combinationsArray,
    })
    
  }

  // Filter out any options that aren't allowed, also
  // filter out any potential duplicates and keep the
  // combination where less poles can be used
  for (var i = combinations.length - 1; i >= 0; i--){
    var dto = combinations[i]
    var firstIndex = combinations.findIndex(o => o.width == dto.width)
    if (firstIndex != i){
      // Remove duplicate
      //console.log(`${dto.width} is duplicate, removing`)
      combinations.splice(i, 1)
      continue;
    }
    if (allowedWidths.includes(dto.width) == false){
      //console.log(`${dto.width} is not in allowed widths, removing`)
      combinations.splice(i, 1)
      continue;
    }
  }

  // Sort by sail width smallest to largest
  combinations.sort((a, b) => {
    if (a.width < b.width)
      return -1
    if (a.width > b.width)
      return 1
    return 0
  })

  return combinations
}


/**
 * Get the combination of poles that make up a specific sail width.
 */
const getPoleCombinationForSailWidth = function(sailWidth) {
  var allWidths = calculateSailWidths()

  var hashMap = {}

  allWidths.forEach(o => {
    hashMap[o.width] = o.poles
  })

  var poles = hashMap[sailWidth]

  if (!poles)
    throw new Error(`The sail width ${sailWidth} is not a valid width. 
      No pole combinations can make this width.`
      .replace(/\s/g, ' ').trim()
    )
  
  return poles

}

/**
 * Given the max fabric drop and the sail length, calculate the number of
 * bridges
 */
const calculateNumberOfBridges = function(params){
  var {
    sailLength,
    maxFabricDrop,
  } = params

  if (typeof sailLength != 'number' || typeof maxFabricDrop != 'number')
    return null
  
  var endBridgeDiameters = (46 + 54)

  // Remove the width of the bridges on the ends of the sail
  // TODO These are likely different sizes on both ends
  var fabricLength = sailLength - endBridgeDiameters

  const pulleyHeight = 50

  // Remove the height of the pulleys off the fabric drop
  maxFabricDrop = maxFabricDrop - pulleyHeight

  var targetNoBridges = fabricLength / (maxFabricDrop * 2)
  //console.log('#targetNoBridges', targetNoBridges)
  var noBridges = Math.ceil(targetNoBridges)
  var fabricLengthBetweenBridges = fabricLength / noBridges
  var fabricDrop = fabricLengthBetweenBridges / 2
  // i.e the drop of the fabric + the height of the pulleys
  var totalFabricDrop = fabricDrop + pulleyHeight

  return {
    // Includes the poles on both ends
    numberOfBridges: noBridges + 1,
    fabricLength,
    drop: totalFabricDrop,
    fabricLengthBetweenBridges,
  }
}


/**
 * Throws if any parameters are missing needed to get the complete total items
 * for a shade sail.
 */
const validateGetShadeSailItemsParams = function(params = {}){
  const {
    surfaceToSurface,
    stackingEndType,
    extendingEndType,
    sailLength,
    maxFabricDrop,
    sailColour,
    sailWidth,
    ropeColour,
    riggingMethod,
    tieOffHornCleatColour,
    extendingEndPartsColour,
    stackingEndPartsColour
  } = params

  assert(surfaceToSurface, 'surfaceToSurface is required.')
  assert(stackingEndType, 'stackingEndType is required.')
  assert(extendingEndType, 'extendingEndType is required.')
  assert(sailLength, 'sailLength is required.')
  assert(maxFabricDrop, 'maxFabricDrop is required.')
  assert(sailColour, 'sailColour is required.')
  assert(sailWidth, 'sailWidth is required.')
  assert(ropeColour, 'ropeColour is required.')
  assert(riggingMethod, 'riggingMethod is required.')
  assert(tieOffHornCleatColour, 'tieOffHornCleatColour is required.')
  assert(extendingEndPartsColour, 'extendingEndPartsColour is required')
  assert(stackingEndPartsColour,'stackingEndPartsColour is required')
  
}

/**
 * Gets the list of items that make up the price, these are the raw costs
 * without any discounts applied.
 */
const getShadeSailItems = function(params = {}){

  const {
    surfaceToSurface,
    stackingEndType,
    extendingEndType,
    sailLength,
    maxFabricDrop,
    sailColour,
    sailWidth,
    ropeColour,
    riggingMethod,
    tieOffHornCleatColour,
    stackingEndExisting,
    extendingEndExisting,
    stackingEndPartsColour,
    extendingEndPartsColour,
  } = params

  var noBridgesResult = calculateNumberOfBridges({
    sailLength,
    maxFabricDrop,
  })

  var items = []

  if (sailWidth){
    var poleCombinations = getPoleCombinationForSailWidth(sailWidth)
    var totalPoleAs = poleCombinations.filter(o => o == 'A').length
    var totalPoleBs = poleCombinations.filter(o => o == 'B').length
    var totalPoleCs = poleCombinations.filter(o => o == 'C').length
  }

  /*var m8RiggingScrewQuantity = 2
  if (wireTensioner == 'm8'){
    m8RiggingScrewQuantity += 2
  }
  var m10TurnbuckleQuantity = 0
  if (wireTensioner == 'm10'){
    m10TurnbuckleQuantity = 2
  }
  var m12TurnbuckleQuantity = 0
  if (wireTensioner == 'm12'){
    m12TurnbuckleQuantity = 2
  }*/

  /*var stackingEndInstallingStructure = computeStackingEndInstallingStructure({
    stackingEndType,
    extendingEndType,
    surfaceToSurface,
    riggingMethod,
  })
  var extendingEndInstallingStructure = computeExtendingEndInstallingStructure({
    stackingEndType,
    extendingEndType,
    surfaceToSurface,
    riggingMethod,
  })*/
    
  priceList.forEach(priceObj => {

    switch(priceObj.key){
      
      case '_6MM_YACHT_CORD_WHITE_ROPE_1': 
        if (!ropeColour || ropeColour == 'black')
          return
      case '_6MM_YACHT_CORD_BLACK_ROPE_1': 
        if (priceObj.key == '_6MM_YACHT_CORD_BLACK_ROPE_1' && ropeColour == 'white')
          return
        // Check for required props
        if (!stackingEndType
          || !extendingEndType
          || !ropeColour
          || !riggingMethod
          || !surfaceToSurface
          || !sailWidth 
          || !sailLength)
          return

        var isPostToPost = stackingEndType === 'POST' && extendingEndType === 'POST'

        if (isPostToPost){

          if (!riggingMethod || riggingMethod === 'FIXED'){
            


            var rope1 = (2 * surfaceToSurface) + (0.6 * sailWidth) + (2 * (sailLength - 500));
            
          } else if (riggingMethod === 'FLOATING'){

            var rope1 = (2 * surfaceToSurface) + (0.6 * sailWidth) + (2 * (sailLength - 500));

          } else {
            throw new Error(`Unknown rigging method ${riggingMethod}.`)
          }
        } else {

          if (!riggingMethod || riggingMethod === 'FIXED'){
            
            var rope1 = (2 * surfaceToSurface) + (sailWidth) + (2 * sailLength) + 1800;

          } else if (riggingMethod === 'FLOATING'){
            
            var rope1 = (2 * surfaceToSurface) + (sailWidth) + (2 * sailLength) + 1800;

          } else {
            throw new Error(`Unknown rigging method ${riggingMethod}.`)
          }
        }
        // to convert millimeters to meters
        var ropeLengthMeters = (rope1) / 1000
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          description: `${formatNumber(ropeLengthMeters)}m`,
          price: ropeLengthMeters * priceObj.price,
          quantity: ropeLengthMeters,
          weightPerItem: priceObj.weight || null,
        })
      return
      case '_6MM_YACHT_CORD_WHITE_ROPE_2': 
        if (!ropeColour || ropeColour == 'black')
          return
      case '_6MM_YACHT_CORD_BLACK_ROPE_2': 
        if (priceObj.key == '_6MM_YACHT_CORD_BLACK_ROPE_2' && ropeColour == 'white')
          return
        // Check for required props
        if (!stackingEndType
          || !extendingEndType
          || !ropeColour
          || !riggingMethod
          || !surfaceToSurface
          || !sailWidth 
          || !sailLength)
          return

        var isPostToPost = stackingEndType === 'POST' && extendingEndType === 'POST'

        if (isPostToPost){

          if (!riggingMethod || riggingMethod === 'FIXED'){
            
          } else if (riggingMethod === 'FLOATING'){

            var rope2 = (sailLength - 500) + (0.4 * sailWidth) + (surfaceToSurface * 1.4);

          } else {
            throw new Error(`Unknown rigging method ${riggingMethod}.`)
          }
        } else {

          if (!riggingMethod || riggingMethod === 'FIXED'){
            
            
          } else if (riggingMethod === 'FLOATING'){
            
            var rope2 = (sailLength - 500) + (0.6 * sailWidth) + (surfaceToSurface * 1.4);

          } else {
            throw new Error(`Unknown rigging method ${riggingMethod}.`)
          }
        }
        if (rope2){
          // to convert millimeters to meters
          var ropeLengthMeters = (rope2) / 1000
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            description: `${formatNumber(ropeLengthMeters)}m`,
            price: ropeLengthMeters * priceObj.price,
            quantity: ropeLengthMeters,
            weightPerItem: priceObj.weight || null,
          })
        }
      return
      case 'SMALL_CLAMP_MONUMENT': 
      case 'SMALL_CLAMP_WESTERN_MYALL': 
      case 'SMALL_CLAMP_WINDSPRAY':
      case 'SMALL_CLAMP_SHALE_GREY': 
      case 'SMALL_CLAMP_CLASSIC_CREAM': 
      case 'SMALL_CLAMP_LEXICON_QTR':
        if (!sailColour || !(
          (priceObj.key == 'SMALL_CLAMP_MONUMENT' && sailColour.key == 'monument') ||
          (
            priceObj.key == 'SMALL_CLAMP_WESTERN_MYALL' 
            // This colour has gone through name changes, so we remain compatible with the different names
            && (sailColour.key == 'deep-grey' || sailColour.key == 'western-myall-dulux' ||  sailColour.key == 'western-myall')
          ) ||
          (priceObj.key == 'SMALL_CLAMP_WINDSPRAY' && sailColour.key == 'windspray') ||
          (priceObj.key == 'SMALL_CLAMP_SHALE_GREY' && sailColour.key == 'shale-grey') ||
          (
            priceObj.key == 'SMALL_CLAMP_CLASSIC_CREAM' 
            // This colour has gone through name changes, so we remain compatible with the different names
            && (sailColour.key == 'classic-sand' || sailColour.key == 'classic-cream')
          ) ||
          (
            priceObj.key == 'SMALL_CLAMP_LEXICON_QTR' 
            // This colour has gone through name changes, so we remain compatible with the different names
            && (sailColour.key == 'snow-white' || sailColour.key == 'lexicon-qtr')
          )
        ))
          return
        if (!noBridgesResult)
          return
        if (!riggingMethod || riggingMethod == 'FIXED'){
          //If fixed, then 2x (number of sheeves - 1)
          var xxClampQuantity = ((noBridgesResult.numberOfBridges - 1) * 2)
          items.push({
            key: priceObj.key,
            label: priceObj.label,		
            price: priceObj.price * xxClampQuantity,
            quantity: xxClampQuantity,
          })
        } else if (riggingMethod == 'FLOATING'){
          //if floating then 2x (number of sheeves-2)
          var xxClampQuantity = ((noBridgesResult.numberOfBridges - 2) * 2)
          items.push({
            key: priceObj.key,
            label: priceObj.label,		
            price: priceObj.price * xxClampQuantity,
            quantity: xxClampQuantity,
          })
        }
        return
      case 'Y_SHACKLE': 
        if (riggingMethod == 'FLOATING'){
          var quantity = 2
        } else {
          var quantity = 3
        }
        items.push({
          key: priceObj.key,
          label: priceObj.label,		
          price: priceObj.price * quantity,
          quantity: quantity,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'LARGE_CLAMP_MONUMENT': 
      case 'LARGE_CLAMP_WESTERN_MYALL': 
      case 'LARGE_CLAMP_WINDSPRAY': 
      case 'LARGE_CLAMP_SHALE_GREY': 
      case 'LARGE_CLAMP_CLASSIC_CREAM': 
      case 'LARGE_CLAMP_LEXICON_QTR':
        if (!sailColour || !(
          (priceObj.key == 'LARGE_CLAMP_MONUMENT' && sailColour.key == 'monument') ||
          (priceObj.key == 'LARGE_CLAMP_WESTERN_MYALL' && (sailColour.key == 'deep-grey' || sailColour.key == 'western-myall-dulux' || sailColour.key == 'western-myall')) ||
          (priceObj.key == 'LARGE_CLAMP_WINDSPRAY' && sailColour.key == 'windspray') ||
          (priceObj.key == 'LARGE_CLAMP_SHALE_GREY' && sailColour.key == 'shale-grey') ||
          (
            priceObj.key == 'LARGE_CLAMP_CLASSIC_CREAM' 
            && (sailColour.key == 'classic-sand' || sailColour.key == 'classic-cream')
          ) ||
          (
            priceObj.key == 'LARGE_CLAMP_LEXICON_QTR' 
            && (sailColour.key == 'snow-white' || sailColour.key == 'lexicon-qtr')
          )
        ))
          return
        if (riggingMethod == 'FLOATING'){
          var quantity = 6
        } else {
          var quantity = 3
        }
        items.push({
          key: priceObj.key,
          label: priceObj.label,		
          price: priceObj.price * quantity,
          quantity: quantity,
          weightPerItem: priceObj.weight || null,
        })

        return
      case 'PULLEY_SHACKLE_SINGLE': 
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: PRICE_LIST.PULLEY_SHACKLE_SINGLE * 1,	
          quantity: 1,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'PULLEY_SHACKLE_DOUBLE':
        if (riggingMethod == 'FIXED'){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.PULLEY_SHACKLE_DOUBLE * 2,	
            quantity: 2,
            weightPerItem: priceObj.weight || null,
          })
        }
        return

      case 'PULLEY_SHACKLE_DOUBLE_WELDED':
        if (riggingMethod == 'FLOATING'){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.PULLEY_SHACKLE_DOUBLE_WELDED,	
            quantity: 1,
            weightPerItem: priceObj.weight || null,
          })
        } 
        return
          
      case 'PULLEY_SHACKLE_TRIPLE':
        if (riggingMethod == 'FLOATING'){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.PULLEY_SHACKLE_TRIPLE * 2,	
            quantity: 2,
            weightPerItem: priceObj.weight || null,
          })
        }
        return

      case 'T_MOUNT_STAINLESS':
      case 'T_MOUNT_MONUMENT':
      case 'T_MOUNT_LEXICON_QTR':
        var quantity = 0;  
        if (
          (priceObj.key == 'T_MOUNT_STAINLESS' && stackingEndPartsColour == 'stainless')
          || (priceObj.key == 'T_MOUNT_MONUMENT' && stackingEndPartsColour == 'monument')
          || (priceObj.key == 'T_MOUNT_LEXICON_QTR' && stackingEndPartsColour == 'lexicon-qtr')
        ){
          quantity += 2
        }
        if (
          (priceObj.key == 'T_MOUNT_STAINLESS' && extendingEndPartsColour == 'stainless')  
          || (priceObj.key == 'T_MOUNT_MONUMENT' && extendingEndPartsColour == 'monument')
          || (priceObj.key == 'T_MOUNT_LEXICON_QTR' && extendingEndPartsColour == 'lexicon-qtr')
        ){
          quantity += 1
        }
        if (quantity > 0){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST[priceObj.key] * quantity,
            quantity: quantity,
            weightPerItem: priceObj.weight || null,
          });
        }
        return
      case 'TWIST_MOUNT_STAINLESS':
      case 'TWIST_MOUNT_MONUMENT':
      case 'TWIST_MOUNT_LEXICON_QTR':
        var quantity = 0;  
        if (
          (priceObj.key == 'TWIST_MOUNT_STAINLESS' && stackingEndPartsColour == 'stainless')
          || (priceObj.key == 'TWIST_MOUNT_MONUMENT' && stackingEndPartsColour == 'monument')
          || (priceObj.key == 'TWIST_MOUNT_LEXICON_QTR' && stackingEndPartsColour == 'lexicon-qtr')
        ){

          //If parts colour is chosen at operating end, for posts add 3, for wall add 0, for beam/arch 2, for cantilever 2.
          switch (stackingEndType){
            case 'POST':
              quantity += 3
              break;
            case 'BEAM':
            case 'CANTILEVER':
              quantity += 2
              break;
            default:
          }
        }
        if (
          (priceObj.key == 'TWIST_MOUNT_STAINLESS' && extendingEndPartsColour == 'stainless')  
          || (priceObj.key == 'TWIST_MOUNT_MONUMENT' && extendingEndPartsColour == 'monument')
          || (priceObj.key == 'TWIST_MOUNT_LEXICON_QTR' && extendingEndPartsColour == 'lexicon-qtr')
        ){

          //If parts colour is chosen at non-operating end, for posts add 2, for wall add 0, for beam/arch add 1, for frame add 1, for cantilever add 1.
          switch (extendingEndType){
            case 'POST':
              quantity += 2
              break;
            case 'BEAM':
            case 'FRAME':
            case 'CANTILEVER':
              quantity += 1
              break;
            default:
          }
        }
        if (quantity > 0){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST[priceObj.key] * quantity,
            quantity: quantity,
            weightPerItem: priceObj.weight || null,
          });
        }
        return
      case 'PLASTIC_SQUARE':
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: PRICE_LIST[priceObj.key],
          quantity: 1,
          weightPerItem: priceObj.weight || null,
        });
      return
      case 'CENTER_PUNCH':
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: PRICE_LIST[priceObj.key],
          quantity: 1,
          weightPerItem: priceObj.weight || null,
        });
      return
               
      case 'HORN_CLEAT_BLACK':
      case 'HORN_CLEAT_WHITE':
        
        if (!tieOffHornCleatColour || !(
          (priceObj.key == 'HORN_CLEAT_BLACK' && tieOffHornCleatColour == 'black') ||
          (priceObj.key == 'HORN_CLEAT_WHITE' && tieOffHornCleatColour == 'white')
        ))
          return
        if (riggingMethod == 'FLOATING'){
          var quantity = 2
        } else {
          var quantity = 1
        }
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: PRICE_LIST[priceObj.key] * quantity,	
          quantity: quantity,
          weightPerItem: priceObj.weight || null,
        })

        return
      case 'DRINK_HOLDER': 
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: PRICE_LIST.DRINK_HOLDER,
          quantity: 1,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'HEX_METAL_SCREW': 
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: 12 * PRICE_LIST.HEX_METAL_SCREW,
          quantity: 12,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'TIMBER_SCREW': 
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: 6 * PRICE_LIST.TIMBER_SCREW,
          quantity: 6,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'WALL_PLUG': 
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: 6 * PRICE_LIST.WALL_PLUG,
          quantity: 6,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'LONG_METAL_SCREW':
         items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: 5 * PRICE_LIST.LONG_METAL_SCREW,
          quantity: 5,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'BUTTON_SCREW':
        if (noBridgesResult){
          var flatHeadScrewQuantity = (noBridgesResult.numberOfBridges * 2) + 3
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.BUTTON_SCREW * flatHeadScrewQuantity,	
            quantity: flatHeadScrewQuantity,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'DRIVER_BIT_PHILLIPS':
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: 1 * PRICE_LIST.DRIVER_BIT_PHILLIPS,
          quantity: 1,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'DRIVER_BIT_HEX': 
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: 1 * PRICE_LIST.DRIVER_BIT_HEX,
          quantity: 1,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'CAP_DARK_2': 
        if (['deep-grey', 'western-myall-dulux', 'monument'].includes(sailColour?.key)){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.CAP_DARK_2 * 2,
            quantity: 2,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'CAP_GREY_2': 
        if (['shale-grey', 'windspray'].includes(sailColour?.key)){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.CAP_GREY_3 * 2,
            quantity: 2,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'CAP_WHITE_2': 
        if (['classic-sand', 'snow-white'].includes(sailColour?.key)){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.CAP_WHITE_2 * 2,
            quantity: 2,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'CAP_DARK_1': 
        if (noBridgesResult && ['deep-grey', 'western-myall-dulux', 'monument'].includes(sailColour?.key)){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.CAP_DARK_1 * (noBridgesResult.numberOfBridges - 1) * 2,
            quantity:	(noBridgesResult.numberOfBridges - 1) * 2,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'CAP_GREY_1': 
        if (noBridgesResult && ['shale-grey', 'windspray'].includes(sailColour?.key)){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.CAP_GREY_1 * (noBridgesResult.numberOfBridges - 1) * 2,
            quantity:	(noBridgesResult.numberOfBridges - 1) * 2,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'CAP_WHITE_1': 
        if (noBridgesResult && ['classic-sand', 'snow-white'].includes(sailColour?.key)){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.CAP_WHITE_1 * (noBridgesResult.numberOfBridges - 1) * 2,
            quantity: (noBridgesResult.numberOfBridges - 1) * 2,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'CAP_DARK_3': 
        if (['deep-grey', 'western-myall-dulux', 'monument'].includes(sailColour?.key)){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.CAP_DARK_3 * 4,
            quantity: 4,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'CAP_GREY_3': 
        if (['shale-grey', 'windspray'].includes(sailColour?.key)){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.CAP_GREY_3 * 4,
            quantity: 4,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'CAP_WHITE_3': 
        if (['classic-sand', 'snow-white'].includes(sailColour?.key)){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.CAP_WHITE_3 * 4,
            quantity: 4,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_A1':
        if (noBridgesResult && totalPoleAs > 0){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_A1 * totalPoleAs * (noBridgesResult.numberOfBridges - 2),	
            quantity: totalPoleAs * (noBridgesResult.numberOfBridges - 2),
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_B1':
        if (noBridgesResult && totalPoleBs > 0){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_B1 * totalPoleBs * (noBridgesResult.numberOfBridges - 2),	
            quantity: totalPoleBs * (noBridgesResult.numberOfBridges - 2),
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_C1':
        if (noBridgesResult && totalPoleCs > 0){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_C1 * totalPoleCs * (noBridgesResult.numberOfBridges - 2),	
            quantity: totalPoleCs * (noBridgesResult.numberOfBridges - 2),
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_D1':
        if (noBridgesResult && poleCombinations?.length > 1){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_D1 * (poleCombinations.length - 1) * (noBridgesResult.numberOfBridges - 2),	
            quantity: (poleCombinations.length - 1) * (noBridgesResult.numberOfBridges - 2),
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_A2':
        if (totalPoleAs > 0 && riggingMethod != 'FLOATING'){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_A2 * totalPoleAs,	
            quantity: totalPoleAs,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_B2':
        if (totalPoleBs > 0 && riggingMethod != 'FLOATING'){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_B2 * totalPoleBs,	
            quantity: totalPoleBs,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_C2':
        if (totalPoleCs > 0 && riggingMethod != 'FLOATING'){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_C2 * totalPoleCs,	
            quantity: totalPoleCs,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_D2':
        if (poleCombinations && poleCombinations.length > 1 && riggingMethod != 'FLOATING'){
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_D2 * (poleCombinations.length - 1),
            quantity: poleCombinations.length - 1, 
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_A3':
        if (totalPoleAs > 0){
          var quantity = totalPoleAs
          if (riggingMethod == 'FLOATING'){
            quantity *= 2
          }
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_A3 * quantity,	
            quantity: quantity,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_B3':
        if (totalPoleBs > 0){
          var quantity = totalPoleBs
          if (riggingMethod == 'FLOATING'){
            quantity *= 2
          }
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_B3 * quantity,	
            quantity: quantity,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_C3':
        if (totalPoleCs > 0){
          var quantity = totalPoleCs
          if (riggingMethod == 'FLOATING'){
            quantity *= 2
          }
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_C3 * quantity,	
            quantity: quantity,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'POLE_D3':
        if (poleCombinations && poleCombinations.length > 1){
          var quantity = (poleCombinations.length - 1)
          if (riggingMethod == 'FLOATING'){
            quantity *= 2
          }
          items.push({
            key: priceObj.key,
            label: priceObj.label,
            price: PRICE_LIST.POLE_D3 * quantity,
            quantity: quantity, 
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      // Manufacturer PDF only needs to show the colour, this here is just for 
      // you to know how much to charge the customer and so we can track stock. 
      // So you will need to create a unique item per colours to track stock, 
      // with the quantity per total area (m2) sail formula as: 
      // (((sail length in meters - 0.11) x (sail width in meters +0.09)) + 
      // ((number of sleeves -2) x (0.135 x (sail width in meters + 0.09))) + 
      // (0.14 x (sail width in meters + 0.09)) + (0.29 x (sail width in meters + 0.09)))
      case 'FABRIC_COST_PER_METRE_SQUARED': 
        if (sailWidth && sailLength && noBridgesResult){

          var sailWidthM = sailWidth / 1000
          var sailLengthM = sailLength / 1000

          var sailMetersSquared = (
            ((sailLengthM - 0.11) * (sailWidthM +0.09)) + 
            + ((noBridgesResult.numberOfBridges -2) * (0.135 * (sailWidthM + 0.09)))
            + (0.14 * (sailWidthM + 0.09))
            + (0.29 * (sailWidthM + 0.09))
          )

          sailMetersSquared = roundTo3Dp(sailMetersSquared)

          items.push({
            key: priceObj.key,
            label: priceObj.label, 
            description: `${formatNumber(sailMetersSquared)}/m²`,
            price: PRICE_LIST.FABRIC_COST_PER_METRE_SQUARED * sailMetersSquared,
            quantity: sailMetersSquared,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'THREAD':
        if (sailWidth && sailLength && noBridgesResult){

          var sailWidthM = sailWidth / 1000
          var sailLengthM = sailLength / 1000
          //((sail width + 0.15) x (number of sleeves x 3.75)) 
          //  + ((sail length + 0.2) x 10))

          var quantity = ((sailWidthM + 0.15) * noBridgesResult.numberOfBridges * 3.75)
            + ((sailLengthM + 0.2) * 10)

          items.push({
            key: priceObj.key,
            label: priceObj.label, 
            price: quantity * PRICE_LIST.THREAD,
            quantity,
            weightPerItem: priceObj.weight || null,
            description: `${formatNumber(quantity)}m`,
          })
        }
        return
      case 'LOGO_TAG_STEEL':
        var quantity = riggingMethod == 'FLOATING' ? 2 : 1
        items.push({
          key: priceObj.key,
          label: priceObj.label, 
          quantity,
          price: priceObj.price * quantity,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'POP_RIVETS_TAG':
        // var quantity = riggingMethod == 'FLOATING' ? 8 : 4
        if(riggingMethod == 'FLOATING'){
          var quantity = 8
        }
        else{
          var quantity = 4
        }
        items.push({
          key: priceObj.key,
          label: priceObj.label, 
          quantity: quantity,
          price: priceObj.price * quantity,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'EYE_BOLT_SET':
        items.push({
          key: priceObj.key,
          label: priceObj.label, 
          price: priceObj.price * 4,
          quantity: 4,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'LASER_MEASURE':
        items.push({
          key: priceObj.key,
          label: priceObj.label, 
          price: priceObj.price,
          quantity: 1,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'DRILL_BITS_4_5MM':
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: 1 * PRICE_LIST.DRILL_BITS_4_5MM,
          quantity: 1,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'MASONRY_DRILL_BIT':
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: 1 * PRICE_LIST.MASONRY_DRILL_BIT,
          quantity: 1,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'DRILL_BIT_11MM':
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: 1 * PRICE_LIST.DRILL_BIT_11MM,
          quantity: 1,
          weightPerItem: priceObj.weight || null,
        })
        return
      /*case 'BOX_A':

        if (sailWidth && sailLength){          
         if (isBoxA({ sailWidth, sailLength })){
            items.push({
              key: priceObj.key,
              label: priceObj.label, 
              price: priceObj.price,
              quantity: 1,
              description: 'Box A',
              weightPerItem: priceObj.weight || null,
            })
          }
        }
        return*/
      case '_4MM_WIRE_ROPE_1X19':
        if (sailWidth && sailLength && surfaceToSurface){
          var sailMetersSquared = sailWidth * sailLength / (1000 * 1000)
          if (sailMetersSquared < 41.5){
            var quantity = (surfaceToSurface / 1000) * 2
            items.push({
              key: priceObj.key,
              label: priceObj.label, 
              price: priceObj.price * quantity,
              quantity: quantity,
              weightPerItem: priceObj.weight || null,
              description: `${formatNumber(quantity)}m`,
            })
          }
        }
        return
      case '_5MM_WIRE_ROPE_1X19':
        if (sailWidth && sailLength && surfaceToSurface){
          var sailMetersSquared = sailWidth * sailLength / (1000 * 1000)
          if (sailMetersSquared >= 41.5){
            var quantity = (surfaceToSurface / 1000) * 2
            items.push({
              key: priceObj.key,
              label: priceObj.label, 
              price: priceObj.price * quantity,
              quantity: quantity,
              weightPerItem: priceObj.weight || null,
              description: `${formatNumber(quantity)}m`,
            })
          }
        }
        return
      case '_4MM_WIRE_ROPE_7X7':
        var totalPosts = 0
        if (stackingEndType == 'POST')
          totalPosts++
        if (extendingEndType == 'POST')
          totalPosts++
        if (sailWidth && totalPosts > 0){
          var sailWidthM = sailWidth / 1000
          var quantity = sailWidthM * 0.6 * totalPosts
          items.push({
            key: priceObj.key,
            label: priceObj.label, 
            price: priceObj.price * quantity,
            quantity: quantity,
            weightPerItem: priceObj.weight || null,
            description: `${formatNumber(quantity)}m`,
          })
        }
        return
      case 'TUBE_MOUNT':
        var totalPosts = 0
        if (stackingEndType == 'POST')
          totalPosts++
        if (extendingEndType == 'POST')
          totalPosts++
        if (totalPosts > 0){
          items.push({
            key: priceObj.key,
            label: priceObj.label, 
            price: priceObj.price * totalPosts,
            quantity: totalPosts,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'FORK_TERMINAL_4MM': 

        var quantity = 0
        if (stackingEndType == 'POST')
          quantity += 2
        if (extendingEndType == 'POST')
          quantity += 2

        // change rule to: '2 PER end with posts as structures (always) + 4 per 
        // sail that is less than 52.5m2'. Anywhere that it mentioned 22m2 can you please change it to 52.5m2. 

        if (sailWidth && sailLength){
          var sailMetersSquared = sailWidth * sailLength / (1000 * 1000)
          if (sailMetersSquared < 41.5){
            quantity += 4
          }
        }
        if (quantity){
          items.push({
            key: priceObj.key,
            label: priceObj.label, 
            price: priceObj.price * quantity,
            quantity: quantity,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'FORK_TERMINAL_5MM':
        if (sailWidth && sailLength){
          var sailMetersSquared = sailWidth * sailLength / (1000 * 1000)
          if (
            sailMetersSquared >= 41.5
          ){
            items.push({
              key: priceObj.key,
              label: priceObj.label, 
              price: priceObj.price * 4,
              quantity: 4,
              weightPerItem: priceObj.weight || null,
            })
          }
        }
        return
      case "_20MM_PULLEY_PIN":
        //if fixed: 2x (number of sleeves -3). If floating 2x (number of sleeves -2)
        if (!noBridgesResult) return;
        var quantity = (noBridgesResult.numberOfBridges - 3) * 2;
        if (riggingMethod === "FLOATING") {
          var quantity = (noBridgesResult.numberOfBridges - 2) * 2;
        }
        items.push({
          key: priceObj.key,
          label: priceObj.label,
          price: priceObj.price * quantity,
          quantity: quantity,
          weightPerItem: priceObj.weight || null,
        });
        return;
      case '_20MM_PULLEY_PIVOT_PIN':
        items.push({
          key: priceObj.key,
          label: priceObj.label, 
          price: priceObj.price * 4,
          quantity: 4,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'STICKY_TAPE': 
        items.push({
          key: priceObj.key,
          label: priceObj.label, 
          price: priceObj.price * 2.2,
          quantity: 2.2,
          weightPerItem: priceObj.weight || null,
          description: `${formatNumber(2.2)}m`,
        })
        return
      case 'ENGINEERING': 
        items.push({
          key: priceObj.key,
          label: priceObj.label, 
          price: priceObj.price,
          quantity: 1,
          weightPerItem: priceObj.weight || null,
        })
        return
      case 'INTERNATIONAL_SHIPPING_BOX_A':
      case 'DOMESTIC_SHIPPING_BOX_A':
        if (isBoxA({ sailWidth, sailLength })){
          items.push({
            key: priceObj.key,
            label: priceObj.label, 
            price: priceObj.price,
            quantity: 1,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'INTERNATIONAL_SHIPPING_BOX_B':
      case 'DOMESTIC_SHIPPING_BOX_B':
        if (isBoxB({ sailWidth, sailLength })){
          items.push({
            key: priceObj.key,
            label: priceObj.label, 
            price: priceObj.price,
            quantity: 1,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
      case 'INTERNATIONAL_SHIPPING_BOX_C':
      case 'DOMESTIC_SHIPPING_BOX_C':
        if (isBoxC({ sailWidth, sailLength })){
          items.push({
            key: priceObj.key,
            label: priceObj.label, 
            price: priceObj.price,
            quantity: 1,
            weightPerItem: priceObj.weight || null,
          })
        }
        return
    }

  })

  /*items = items.map(o => ({
    ...o,
    note: o.quantity,
    price: formatNumber(o.price) + '$',
  }))*/

  items = items.map(o => ({
    ...o,
    // All prices are at most in 3 significant figures
    // so round to avoid JS rounding errors,
    price: roundTo3Dp(o.price),
  }))

  return items
}

const computeStackingEndInstallingStructure = function(params){
  
  const {
    stackingEndType,
    extendingEndType,
    riggingMethod,
  } = params

  if (riggingMethod == 'FLOATING'){
    switch (stackingEndType){
      case 'WALL':
        return 'D'
      case 'BEAM':
        return 'C'
      case 'POST':
        return 'A'
      default:
        return null
    }
  }

  switch (stackingEndType){
    case 'WALL':
      return 'D'
    case 'BEAM':
      return 'B'
    case 'POST':
      return 'A'
    default:
      return null
  }
}

const computeExtendingEndInstallingStructure = function(params){
  const {
    stackingEndType,
    extendingEndType,
    riggingMethod,
    surfaceToSurface,
  } = params

  if (riggingMethod == 'FLOATING'){
    switch (stackingEndType){
      case 'WALL':
        switch (extendingEndType){
          case 'WALL':
            return 'D'
          case 'BEAM':
            if (!surfaceToSurface)
              return null

            // E: ("C) Beam: threaded terminals" image, "install widget: iii"), tension: <14m threaded terminal, 
            if (surfaceToSurface <= 14000){
              return 'C'
            }
            // If 14m+ between structures, then: E: eye nut ("B) Beam: welded eyes or plates" image, "install widget: ii")
            return 'B'
          case 'POST':
          case 'FRAME':
            // E: eye nut ("A) Posts: welded eyes or plates" image, "install widget: ii"),
            return 'A'
          default: return null
        }
      case 'BEAM':
        switch (extendingEndType){
          case 'WALL':
            // E: eye nut ("D) Wall/building: tailored fixing" image, "install widget: ii")
            return 'D'
          case 'BEAM':
            if (!surfaceToSurface)
              return null
            // E: ("C) Beam: threaded terminals" image, 'intall widget iii'), tension: <14m threaded terminal. 
            if (surfaceToSurface <= 14000){
              return 'C'
            }
            // If 14m+ between structures, then: E: eye nut ("B) Beam: welded eyes or plates" image, ii), tension: M12 turnbuckle
            return 'B'
          case 'POST':
          case 'FRAME':
            // E: eye nut ("A) Posts: welded eyes or plates" image, "install widget: ii")
            return 'A'
          default: return null
        }
      case 'POST':
        switch (extendingEndType){
          case 'WALL':
            // E: eye nut ("D) Wall/building: tailored fixing" image, "install widget: ii")
            return 'D'
          case 'BEAM':
            if (!surfaceToSurface)
              return null
            // E: ("C) Beam: threaded terminals" image, "install widget: iii"), 
            if (surfaceToSurface <= 14000){
              return 'C'
            }
            // If 14m+ between structures, then: E: eye nut ("B) Beam: welded eyes or plates" image, "install widget: ii")
            return 'B'
          case 'POST':
          case 'FRAME':
            // E: eye nut ("A) Posts: welded eyes or plates" image, "install widget: ii")
            return 'A'
          default: return null
        }
      default:
        return null
    }
  }

  switch (stackingEndType){
    case 'WALL':
      switch (extendingEndType){
        case 'WALL':
          // E: eye nut ("D) Wall/building: tailored fixing" image, "install widget: ii")
          return 'D'
        case 'BEAM':
          if (!surfaceToSurface)
            return null
          // E: ("C) Beam: threaded terminals" image, "install image: iii"), 
          if (surfaceToSurface <= 14000){
            return 'C'
          }
          // If 14m+ between structures, then: E: eye nut ("B) Beam: welded eyes or plates" image, "install widget: ii"),
          return 'B'
        case 'POST':
        case 'FRAME':
          // E: eye nut ("A) Posts: welded eyes or plates" image, "install widget: ii"),
          return 'A'
        default: return null
      }
    case 'BEAM':
      switch (extendingEndType){
        case 'WALL':
          // E: eye nut ("D) Wall/building: tailored fixing" image, "install widget: ii")
          return 'D'
        case 'BEAM':
          if (!surfaceToSurface)
            return null
          // E: ("C) Beam: threaded terminals" image"install widget: iii"), tension: <14m threaded terminal. 
          if (surfaceToSurface <= 14000){
            return 'C'
          }
          // If 14m+ between structures, then: E: eye nut ("B) Beam: welded eyes or plates" image, "install widget: ii")
          return 'B'
        case 'POST':
        case 'FRAME':
          // E: eye nut ("A) Posts: welded eyes or plates" image, "install widget: ii")
          return 'A'
        default: return null
      }
    case 'POST':
      switch (extendingEndType){
        case 'WALL':
          // E: eye nut ("D) Wall/building: tailored fixing" image, "install widget: ii")
          return 'D'
        case 'BEAM':
          if (!surfaceToSurface)
            return null
          // E: ("C) Beam: threaded terminals" image, "install widget: iii"), 
          if (surfaceToSurface <= 14000){
            return 'C'
          }
          // If 14m+ between structures, then: E: eye nut ("B) Beam: welded eyes or plates" image, "install widget: ii")
          return 'B'
        case 'POST':
        case 'FRAME':
          // E: eye nut ("A) Posts: welded eyes or plates" image, "install widget: ii"),
          return 'A'
        default: return null
      }
    default:
      return null
  }
}

// Updated box logic at
// https://linear.app/shadespot/issue/SHA-12/shipping-label#comment-357fc938
// Box A: sail meters squared > 53
// Box B: sail meters squared <= 53 and sail meters squared  > 30
// Box C: sail meters squared <= 30
const isBoxA = function(params){
  const { sailWidth, sailLength } = params

  var sailMetersSquared = sailWidth * sailLength / (1000 * 1000)
          
  return sailMetersSquared > 50
}

const isBoxB = function(params){
  const { sailWidth, sailLength } = params
  var sailMetersSquared = sailWidth * sailLength / (1000 * 1000)
          
  return (sailMetersSquared <= 50 && sailMetersSquared > 30)
}

const isBoxC = function(params){
  const { sailWidth, sailLength } = params
  var sailMetersSquared = sailWidth * sailLength / (1000 * 1000)
          
  return sailMetersSquared <= 30
}

const getBox = function(params){
  if (isBoxA(params))
    return 'A'
  if (isBoxC(params))
    return 'C'
  return 'B'
}

const SELF_INSTALL_DISCOUNT = 0.33

const wireTensioners = [
  {
    label: 'Threaded terminal (<7m)',
    value: 'threaded-terminal',
    specification: `Aesthetically this is the most minimalistic looking tensioner, the wire rope inserts into a steel rod that has thread at the other end. The threaded end is inserted through a hole in the structure and tensioned with a nut on the other side which draws it through.
Adjustment range: 60mm/6cm, gap needed at far/non-operating end (sail to structure): 60mm/6cm, standard gap needed at start/operating end (sail to structure): 70mm/7cm.
*Given spacing requirements, sail must be at least 130mm/13cm shorter (60mm+70mm) than total length between inner surfaces of structures, at height of wires`,
  },
  {
    label: 'M8 rigging screw (<7m)',
    value: 'm8',
    specification: `Due to its closed body, this is a sleek looking, jaw-to-jaw tensioner. Rotation of the centre piece draw the arms inwards and creates tension on the wire rope.
Adjustment range: 80mm/8cm, gap needed at far/non-operating end (sail to structure): 340mm/34cm, standard gap needed at start/operating end (sail to structure): 70mm/7cm.
*Given spacing, sail must be at least 410mm/41cm shorter (340mm+70mm) than total length between inner surfaces of structures, at height of wires`,
  },
  {
    label: 'M10 turnbuckle (7-12.5m)',
    value: 'm10',
    specification: `This open body, jaw-to-jaw tensioner allows greater adjustment range and ease of use. Rotation of the centre piece with a large spanner draws the arms inwards and creates tension on the wire rope.
Adjustment range: 95mm/9.5cm, gap needed at far/non-operating end (sail to structure): 400mm/40cm, standard gap needed at start/operating end (sail to structure): 70mm/7cm.
*Given spacing, sail must be at least 470mm/41cm shorter (400mm+70mm) than total length between inner surfaces of structures, at height of wires`,
  },
  {
    label: 'M12 turnbuckle (12.5m+)',
    value: 'm12',
    specification: `This large open body, jaw-to-jaw tensioner allows the greatest adjustment range and ease of use. Rotation of the centre piece with a large spanner draws the arms inwards and creates tension on the wire rope.
Adjustment range: 145mm/14.5cm, gap needed at far/non-operating end (sail to structure): 450mm/45cm, standard gap needed at start/operating end (sail to structure): 70mm/7cm.
*Given spacing, sail must be at least 520mm/52cm shorter (450mm+70mm) than total length between inner surfaces of structures, at height of wires`,
  },
]


const wallOptions = [
  {
    value: 'WALL',
    label: 'Wall/Building',
  },
  {
    value: 'BEAM',
    label: 'Beam/Arch',
  },
  {
    value: 'POST',
    label: 'Posts',
  },
  {
    value: 'CANTILEVER',
    label: 'Cantilever',
  },
  {
    value: 'FRAME',
    label: 'Frame',
  },
]

/**
 * Get the ratio you would need to apply to this unit to convert it to mm
 */
const getUnitRatioToMM = unit => {

  switch (unit){
    case 'm': return  1000
    case 'cm': return 10
    case 'mm': 
    default: return 1
  }

}

// Map the priceList to a hashmap so it's quicker to iterate
var priceListHashmap = {}
priceList.forEach(dto => {
  priceListHashmap[dto.key] = dto
})

const calculateWeightOfBox = function(params){
  const { items } = params

  var weight = items.reduce((pre, cur) => {
    var priceItem = priceListHashmap[cur.key]
    
    if (!priceItem) return pre

    if (!priceItem.weight)
      return pre

    switch(cur.key){
      case '_6MM_YACHT_CORD_BLACK':
      case '_6MM_YACHT_CORD_WHITE':
      case '_4MM_WIRE_ROPE_1X19':
      case '_5MM_WIRE_ROPE_1X19':
      case '_6MM_WIRE_ROPE_1X19':
      case '_4MM_WIRE_ROPE_7X7':
        // per meter
        return pre + ((cur.quantity || 1) * priceItem.weight)
      case 'FABRIC_COST_PER_METRE_SQUARED': 
        // per meter squared
        return pre + ((cur.quantity || 1) * priceItem.weight)
      default: 
        //var temp = pre + priceItem.weight
        //if (Number.isNaN(temp)){
        //  debugger
        //}
        return pre + priceItem.weight
    }
  }, 0)
  if (Number.isNaN(weight)){
    return null
  }

  return weight
}

const formatToReadablePoleCombinations = function(poleCombinations){
  return poleCombinations.join(' - ')
}

module.exports = {
  calculateSailWidths,
  getPoleCombinationForSailWidth,
  calculateNumberOfBridges,
  PRICE_LIST,
  getShadeSailItems,
  validateGetShadeSailItemsParams,
  wireTensioners,
  wallOptions,
  getUnitRatioToMM,
  POLE_A_LENGTH,
  POLE_B_LENGTH,
  POLE_C_LENGTH,
  isBoxA,
  isBoxB,
  isBoxC,
  getBox,
  SELF_INSTALL_DISCOUNT,
  computeStackingEndInstallingStructure,
  computeExtendingEndInstallingStructure,
  calculateWeightOfBox,
  formatToReadablePoleCombinations,
}