import * as React from 'react'
import reducer, { defaultState } from './reducer'
import { 
  addSailToCart, 
  deleteSailFromCart, 
  setPostcode, 
  updateSailInCart,
  setShippingInformation,
  clearCart,
  clearStripePaymentIntent,
  applySelfInstallDiscount,
  removeSelfInstallDiscount,
  setCouponCode,
  setCouponCodeResult,
  setCouponCodeFetching,
  setCouponCodeError,
  setShippingAddress,
  setSelectedCouponCode,
  setSelectedFreeWarrantyCouponCode,
  setSelectedNoInstallationKitCouponCode,
  clearSelectedCouponCode,
  clearSelectedFreeWarrantyCouponCode,
  clearSelectedNoInstallationKitCouponCode,
  clearExtendedWarranty,
  addExtendedWarranty,
  showWhatHappensNextDialog,
  hideWhatHappensNextDialog,
} from './actions'
import { CartContext } from './context'
import { SELF_INSTALL_DISCOUNT } from './../../components/OrderContentBlock/utilNode.cjs'
import { getShadeSailItems } from './../../components/OrderContentBlock/util'

const { useReducer, useRef } = React

const CartLogic = props => {
  const { children } = props

  const [reducerState, dispatch] = useReducer(reducer, defaultState)
  const fetchCouponCodeRef = useRef({timeout: 0}).current

  const fetchCouponCode = (couponCode) => {
    
    dispatch(setCouponCodeError(null))
    dispatch(setCouponCodeResult(null))

    if (!couponCode)
      return
    dispatch(setCouponCodeFetching(true))

    fetch(
      process.env.GATSBY_STRIPE_API + '/coupon-code/' + couponCode
    )
      .then(response => {
        return response.json()
          .then(result => {
            dispatch(setCouponCodeFetching(false))
            if (response.status != 200){
              throw new Error(result.message || 'Unable to fetch coupon code information.')
            }

            if (result['couponCode']?.['coupon']?.['type'] == 'FREEWARRANTY'){
              dispatch(setSelectedFreeWarrantyCouponCode(result))
              return
            }
            if (result['couponCode']?.['coupon']?.['type'] == 'NOINSTALLATIONKIT'){
              dispatch(setSelectedNoInstallationKitCouponCode(result))
              return
            }

            dispatch(setCouponCodeResult(result))

            if (result.couponCode){
              dispatch(setSelectedCouponCode(result))
            }

          })
      })
      .catch(error => {
        console.error(error)
        dispatch(setCouponCodeFetching(false))
        dispatch(setCouponCodeError(error))
        throw error
      })
  }

  var couponDiscount = 0
  var couponPercentDiscount = 0
  var coupon = reducerState.selectedCouponCodeResult?.couponCode?.coupon
  if (coupon){
    if (coupon.amount_off){
      couponDiscount = coupon.amount_off
    } else if (coupon.percent_off) {
      couponPercentDiscount = coupon.percent_off
    }
  }

  return <CartContext.Provider
    value={{
      addSailToCart: (sail) => {
        dispatch(addSailToCart(sail))
        if (typeof window.fbq !== 'undefined'){
          
          const items = getShadeSailItems(sail)

          var total = items.reduce((pre, cur) => {
            return pre + (cur.price * (1 - SELF_INSTALL_DISCOUNT))
          }, 0)
          total = Math.round(total)

          window.fbq('track', 'AddToCart', {
            currency: 'AUD',
            // This is the value without GST
            value: total / 100,
          })
        }
      },
      deleteSailFromCart: sail => {
        dispatch(deleteSailFromCart(sail))
      },
      updateSailInCart: (sail) => {
        dispatch(updateSailInCart(sail))
      },
      setPostcode: postcode => {
        dispatch(setPostcode(postcode))
      },
      setShippingInformation: shippingInfo => {
        dispatch(setShippingInformation(shippingInfo))
      },
      clearCart: () => {
        dispatch(clearCart())
      },
      clearStripePaymentIntent: () => {
        dispatch(clearStripePaymentIntent())
      },
      applySelfInstallDiscount: () => dispatch(applySelfInstallDiscount()),
      removeSelfInstallDiscount: () => dispatch(removeSelfInstallDiscount()),
      dispatch,
      reducerState,

      showWhatHappensNextDialog: () => dispatch(showWhatHappensNextDialog()),
      hideWhatHappensNextDialog: () => dispatch(hideWhatHappensNextDialog()),

      updateCouponCode: (couponCode) => {
        dispatch(setCouponCode(couponCode))
        clearTimeout(fetchCouponCodeRef.timeout)
        fetchCouponCodeRef.timeout = setTimeout(() => fetchCouponCode(couponCode), 200)
      },
      // TODO Should probably remove these two and infer from the result
      couponDiscount,
      couponPercentDiscount,
      setShippingAddress: address => dispatch(setShippingAddress(address)),
      clearSelectedCouponCode: () => dispatch(clearSelectedCouponCode()),
      clearSelectedFreeWarrantyCouponCode: () => dispatch(clearSelectedFreeWarrantyCouponCode()),
      clearSelectedNoInstallationKitCouponCode: () => dispatch(clearSelectedNoInstallationKitCouponCode()),
      clearExtendedWarranty: () => dispatch(clearExtendedWarranty()),
      addExtendedWarranty: () => dispatch(addExtendedWarranty()),
    }}
  >
    {children}
  </CartContext.Provider>
}

export default CartLogic
